import { rgba } from 'polished'
import React, { useState } from 'react'
import styled from 'styled-components'
import Container from './Container'
import Calendar from './Icons/Calendar'
import ArrowRight2 from '../components/Icons/ArrowRight2'
import AddToGoogleCalendarButton from "./AddToGoogleCalendarButton";
import Exclamation from '../assets/images/exclamationIcon.svg'
import Checkmark from '../assets/images/checkmarkIcon.svg'


const ScheduleToggler = ({ isActive, text, subtext, startDate, endDate, status, number = 1, half }) => {
    const [isOpen, setIOpen] = useState(false)

    const handleClick = () => {
        isActive && setIOpen(prev => !prev)
    }
    return (
        <StyledScheduleToggler disablePaddingTop onClick={handleClick} number={number}>
            <Wrapper>
                <Content>
                    <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                        <div>
                            <StyledCalendar />
                        </div>
                        <Text status={half >= 8 ? status : 'FINISHED'}>{text}</Text>
                        <Subtext>{subtext}</Subtext>
                    </div>
                    <div>
                        {status && half >= 8 &&
                            <StyledTag>
                                    <Image src={status === 'FINISHED' ? Checkmark : Exclamation} alt='Status' />
                                    <TextTag>{status === 'FINISHED' ? "Wykonane!" : "Do zrobienia!"}</TextTag>
                            </StyledTag>

                    }
                    </div>
                    <div style={{marginRight: '20px'}}>
                        <AddToGoogleCalendarButton title={subtext} startDate={startDate} endDate={endDate} details={subtext} location={''} />
                    </div>
                    <div style={{display: 'flex', minWidth: '20px'}}>
                        {
                            isActive && (
                                <ArrowWrapper isOpen={isOpen} >
                                    <StyledArrowRight2 />
                                </ArrowWrapper>
                            )
                        }
                    </div>
                </Content>
            </Wrapper>
        </StyledScheduleToggler>
    )
}

export default ScheduleToggler


const StyledScheduleToggler = styled(Container)`
    ${props => props.number % 2 === 1 && `
        background-color: ${rgba(props.theme.colors.primary, 0.19)};
    `}
    &:hover {
        background-color: ${props => props.number % 2 === 1 ? rgba(props.theme.colors.primary, 0.4) : rgba(props.theme.colors.lightGray, 1)};
    }   

`

const Wrapper = styled.div`
    max-width: 1170px;
    margin: auto;  
`

const StyledCalendar = styled(Calendar)`
    display: flex;
    fill: ${props => props.theme.colors.primary};
`

const Text = styled.div`
    font-size: 24px;
    font-family: 'Merriweather', serif;
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
    margin-left: 5%;
    min-width: 345px;
    color: ${props => props.status === 'FINISHED' ? props.theme.colors.primary : props.theme.colors.red};
`

const Subtext = styled.div`
    margin-left: 20px;
    font-size: 20px;
    font-family: 'Lato', serif;
    font-weight: normal;
    color: black;
`

const StyledArrowRight2 = styled(ArrowRight2)`
    fill: ${props => props.theme.colors.primary};
`

const Content = styled.div`
    max-width: 100%;
    min-height: 74px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
`

const ArrowWrapper = styled.div`
    margin-left: auto;
    transition: all 0.3s ease-in-out;
    transform: rotate(${props => props.isOpen ? '90deg' : '0deg'});
`
const Image = styled.img`
    display: block;
    width: 50px;
    height: 50px;
`

const TextTag = styled.div`
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-20%, -50%);
    min-width: 100%;
    background-color: white;
    border: 1px solid ${props => props.theme.colors.primary};
    text-align: center;
    padding: 5px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
`

const StyledTag = styled.div`
    width: 86px;
    height: 86px;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        ${TextTag} {
            opacity: 1!important;
        }
    }
`