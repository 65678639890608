import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Description from './Description'
import CountDown from './CountDown'
import {useStoreState} from "easy-peasy";
const ProTip = ({ title, description, deadline, ...props }) => {

    const edition = useStoreState(state => state.user.edition)

    return (
        <>
            {deadline && edition === "2" &&
            <CountDown deadline={deadline} />
            }
            <ProTipStyled {...props}>
                <Title>{title}</Title>
                <Description dangerouslySetInnerHTML={{ __html: description }} />
            </ProTipStyled>
        </>
    )
}

export default ProTip

const ProTipStyled = styled.div`
    max-width: 400px;
    width: 100%;
    padding: 16px 32px;
    background-color: ${props => rgba(props.theme.colors.yellowGreen, 0.23)};
    border: 1px solid ${props => props.theme.colors.yellowGreen};
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 60%;
        height: 10px;
        background-color: ${props => props.theme.colors.yellowGreen};
    }
   
`

const Title = styled.div`
    font-size: 24px;
    font-weight: bold;
    line-height: 38px;
    color: ${props => props.theme.colors.yellowGreen};
    margin-bottom: 8px;
`