import React from 'react'
import styled from 'styled-components'
import Link from '../Link'
import Logo from '../Logo'
import Search from '../Search'
import { useLocation, useNavigate } from 'react-router-dom'
import { useStoreActions, useStoreState } from 'easy-peasy'
import Building from '../Icons/Building'
import Speaker from '../Icons/Speaker'
import Graduade from '../Icons/Graduade'
import Chat from '../Icons/Chat'
import User from '../Icons/User'
import Toast from "../Toast";
import { rgba } from 'polished'

const DesktopNav = () => {
    const isTeacher = useStoreState(state => state.auth.roles.includes('ROLE_LECTURER'))
    const navigate = useNavigate()

    const { pathname } = useLocation()
    const logout = useStoreActions(actions => actions.auth.logoutUser)
    const chairLevelSlug = useStoreState(state => state.user.chairLevelSlug)

    const handeLogout = () => {
        logout();
        Toast({
            type: 'success',
            title: 'Zostałeś prawidłowo wylogowany',
        });
    }

    return (
        <StyledNav>
            <LeftSide>
                <Search onClick={() => navigate('/search')} iconcolor={'white'} />
                <List>
                    <ListItem
                        isActive={/about/.test(pathname)}
                    >
                        <StyledLink to='/about' >
                            <Building className={`icon ${/about/.test(pathname) ? 'active' : ''}`} />
                            <Category>
                                O Uniwersytecie
                            </Category>
                        </StyledLink>
                        <SubMenu>
                            <SubMenuItem>
                                <SubMenuLink to='/about/chairs'>
                                    katedry
                                </SubMenuLink>
                            </SubMenuItem>
                            <SubMenuItem>
                                <SubMenuLink to='/about/teachers'>
                                    wykładowcy
                                </SubMenuLink>
                            </SubMenuItem>
{/*                            <SubMenuItem>
                                <SubMenuLink to='/for-inquisitive'>
                                    dla dociekliwych
                                </SubMenuLink>
                            </SubMenuItem>*/}
                        </SubMenu>
                    </ListItem>
                    <ListItem isActive={/news/.test(pathname)} >
                        <StyledLink to='/news/' >
                            <Speaker className={`icon ${/news/.test(pathname) ? 'active' : ''}`} />
                            <Category>
                                Aktualności
                            </Category>
                        </StyledLink>
                    </ListItem>
                </List>
            </LeftSide>
            <Link to='/'>
                <StyledLogo
                    color='white'
                />
            </Link>
            <RightSide>
                <List>
                    {
                        isTeacher ? (
                            <ListItem isActive={/my-studies/.test(pathname)} >
                                <StyledLink to='/my-studies/lecturer-exams' >
                                    <Graduade className={`icon ${/my-studies/.test(pathname) ? 'active' : ''}`} />
                                    <Category>
                                        Egzaminy
                                    </Category>
                                </StyledLink>
                            </ListItem>
                        ) : (
                            <ListItem isActive={/my-studies/.test(pathname)} >
                                <StyledLink to='/my-studies' >
                                    <Graduade className={`icon ${/my-studies/.test(pathname) ? 'active' : ''}`} />
                                    <Category>
                                        Moje studia
                                    </Category>
                                </StyledLink>
                                <SubMenu>
                                    <SubMenuItem>
                                        <SubMenuLink to='/my-studies/tasks'>
                                            zadania
                                        </SubMenuLink>
                                    </SubMenuItem>
                                    <SubMenuItem>
                                        <SubMenuLink to={`/about/chairs/${chairLevelSlug}`}>
                                            program
                                        </SubMenuLink>
                                    </SubMenuItem>
                                    <SubMenuItem>
                                        <SubMenuLink to={`/my-studies/library`}>
                                            biblioteka
                                        </SubMenuLink>
                                    </SubMenuItem>
                                    <SubMenuItem>
                                        <SubMenuLink to={`/trophy`}>
                                            trofea
                                        </SubMenuLink>
                                    </SubMenuItem>
                                    <SubMenuItem>
                                        <SubMenuLink to='/contact'>
                                            kontakt
                                        </SubMenuLink>
                                    </SubMenuItem>
                                    {/*<SubMenuItem className='special'>
                                        <SubMenuLink to='/my-studies/exams'>
                                            egzaminy
                                        </SubMenuLink>
                                    </SubMenuItem>*/}
                                </SubMenu>
                            </ListItem>
                        )
                    }
                    <ListItem isActive={/faq/.test(pathname)}>
                        <StyledLink to='/faq'>
                            <Chat className={`icon ${/faq/.test(pathname) ? 'active' : ''}`} />
                            <Category>
                                FAQ
                            </Category>
                        </StyledLink>
                    </ListItem>
                    <ListItem isActive={false} isHighlighted={true}>
                        <StyledLink to='/login' onClick={handeLogout}>
                            <User className={`icon ${/login/.test(pathname) ? 'active' : ''}`} />
                            <Category>
                                Wyloguj się
                            </Category>
                        </StyledLink>
                    </ListItem>
                </List>
            </RightSide>
        </StyledNav >
    )
}

export default DesktopNav

const SubMenu = styled.ul`
    display: none;
    position: absolute;
    transform: translateY(calc(100% + 10px));
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.yellowGreen};
    border-bottom: 10px solid #F9E52D;
`

const SubMenuItem = styled.li`
    transition: all 0.3s ease-in-out;
    color: ${props => props.theme.colors.yellowGreen};
    &:hover {
        background-color: ${props => rgba(props.theme.colors.yellowGreen, 0.6)};
        color: ${props => props.theme.colors.white};
    }
    &.special {
        background-color: ${props => props.theme.colors.primary};
        color: #F9E52D;
    }
    &.special:hover {
        background-color: ${props => rgba(props.theme.colors.primary, 0.6)};
        color: ${props => props.theme.colors.white};
    }
`

const SubMenuLink = styled(Link)`
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
`

const StyledNav = styled.nav`
    background-color: ${props => props.theme.colors.yellowGreen};
    display: flex;
    gap: 50px;
    color: ${props => props.theme.colors.white};
    padding: 0px 50px;
    
`
const StyledLink = styled(Link)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%;
`

const List = styled.ul`
    display: flex;
    
`

const ListItem = styled.li`
    padding-top:10px;
    display: flex;
    align-items:center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    width: 150px;
    opacity: ${props => !props.disabled ? '1' : '0.3'};
    color: ${props => props.isActive ? props.theme.colors.yellowGreen : props.theme.colors.white}; 
    background-color: ${props => props.isActive ? props.theme.colors.white : 'none'}; 
    border-bottom: 10px solid ${props => props.isActive ? props.theme.colors.yellow : props.theme.colors.yellowGreen}; 
    position: relative;
    .icon {
        transition: all 0.3s ease-in-out;
        fill: ${props => props.theme.colors.white};
    }
    .icon.active {
        fill: ${props => props.theme.colors.yellowGreen}; 
    }
    &:hover {
        background-color: ${props => !props.disabled && !props.isHighlighted && props.theme.colors.white}; 
        color: ${props => !props.disabled && !props.isHighlighted && props.theme.colors.yellowGreen}; 
        border-bottom: 10px solid ${props => !props.disabled && !props.isHighlighted ? props.theme.colors.yellow : props.theme.colors.yellowGreen}; 
        ${SubMenu} {
            display: block;
        }
        .icon:not(.disabled) {
            fill: ${props => props.theme.colors.yellowGreen}; 
        }
    }
`



const Category = styled.div`
    
`

const LeftSide = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 20px;
   
`

const RightSide = styled.div`
    flex: 1;
    display: flex;
`

const StyledLogo = styled(Logo)`
    min-width: 70px;
    max-width: 70px;
    margin: 10px 0px;
`




