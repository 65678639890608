import React from 'react'
import Container from '../components/Container'
import BigTitle from "../components/BigTitle";
import Title from '../components/Title'
import styled from "styled-components";
import StageCard from "../components/StageCard";
import Avatar from "../components/Avatar";
import ProgressBar from "../components/ProgressBar";
import tasks from "../assets/images/tasks.svg";
import program from "../assets/images/program.svg";
import calender from "../assets/images/calender.svg";
import books from "../assets/images/books.svg";
import pencil from "../assets/images/pencil.svg";
import exam from "../assets/images/exam.svg";
import hat from "../assets/images/graduation_hat.svg";
import theme from "../theme";
import Slider from "../components/Slider";
import { slide1Data } from "../FakeData/SliderData";
import Card from "../components/Card";
import { useQuery } from "@tanstack/react-query";
import userService from "../services/userService";
import useMediaQuery from '../Hooks/MediaQueryHook';
import Spiner from '../components/Spiner';
import trophy from "../assets/images/trophy.svg";
import exam2 from "../assets/images/exam2.svg";
// import { useStoreState } from 'easy-peasy';


const stagesData = [
  {
    icon: tasks,
    title: 'Zadania',
    desc: 'Zajrzyj i zobacz, co na Ciebie czeka',
    link: '/my-studies/tasks',
  },
  {
    icon: program,
    title: 'Program',
    desc: 'Poznaj program swojej katedry',
    link: '/DYNAMIC_CHAIR'
  },
  {
    icon: calender,
    title: 'Terminarz',
    desc: 'Pamiętaj, aby dobrze zaplanować czas',
    link: '/my-studies/schedule',
  },
  {
    icon: books,
    title: 'Biblioteka',
    desc: 'Tu znajdziesz materiały do nauki',
    link: '/my-studies/library',

  },
  {
    link: '/trophy',
    title: 'Trofea',
    desc: 'Ucz się, baw i zdobywaj nagrody',
    icon: trophy,
  },
  {
    link: '/contact',
    title: 'Kontakt',
    desc: 'Kto pyta, nie błądzi',
    icon: pencil,
  },
  {
    icon: exam,
    title: 'Zaliczenia',
    desc: 'Nadszedł czas, by sprawdzić wiedzę',
    color: theme.colors.yellowGreen,
    disabled: 'disabled'
  },
  {
    icon: hat,
    title: 'Kontynuuj',
    desc: 'Czeka na Ciebie rozpoczęte zadanie',
    color: theme.colors.yellowGreen,
    disabled: false,
  },
  {
    icon: exam2,
    title: 'Egzaminy',
    desc: 'Nadszedł czas, by sprawdzić wiedzę',
    color: theme.colors.yellow2,
    disabled: true,
    link: '/my-studies/exams',
  }
]

/*const lecturerData = {
  icon: exam2,
  title: 'Egzaminy',
  desc: 'Sprawdź wiedzę swoich studentów',
  color: theme.colors.yellow2,
  disabled: true,
  link: '/my-studies/lecturer-exams',
}*/


const Studies = () => {
  // const isTeacher = useStoreState(state => state.auth.roles.includes('ROLE_LECTURER'))
  const isMobile = useMediaQuery(`(max-width: 500px`)

  const {
    data: dataUserMe,
    isSuccess: isSuccessUserMe,
    isLoading: isLoadingUserMe
  } = useQuery(
    ['userMe'],
    userService.userMe()
  )

  if (isLoadingUserMe) {
    return (
      <>
        {
          isLoadingUserMe && <Spiner />
        }
      </>
    )
  }
  if (isSuccessUserMe) {

    const {
      firstName,
      lastName,
      image,
      universityChairLevel: {
        level: { name: levelName },
        universityChair: { name: universityChairName }
      }
    } = dataUserMe;

    return (
      <>
        <Container>
          <BigTitle>
            Moje studia
          </BigTitle>
          <Wrapper>
            <MainSection>
              <div className={'container'}>
                <Title style={{ textAlign: 'left', marginBottom: '23px', fontWeight: 'normal' }}>
                  Witaj {firstName} {lastName}
                </Title>
                <Term>
                  <dt>
                    Katedra:
                  </dt>
                  <dd>
                    {universityChairName}
                  </dd>
                </Term>
                <Term>
                  <dt>
                    Poziom:
                  </dt>
                  <dd>
                    {levelName}
                  </dd>
                </Term>

                {
                  isMobile ? (
                    <Slider
                      data={[stagesData[0], stagesData[1], {disabled: dataUserMe?.universityChairLevel.halfSet.half.edition.symbol === "1", ...stagesData[2]}, stagesData[3], stagesData[4], stagesData[5]]}
                      component={<StageCard />}
                      breakpoints={{
                        1400: {
                          slidesPerView: 3
                        },
                        900: {
                          slidesPerView: 2
                        },
                      }}
                      isCenter={true}
                      bgColor={'white'}
                    />
                  ) : (
                    <Stages>
                      <StageCard
                        data={stagesData[0]}
                      />
                      <StageCard
                        data={stagesData[1]}
                      />
                      <StageCard
                        data={{disabled: dataUserMe?.universityChairLevel.halfSet.half.edition.symbol === "1",  ...stagesData[2]}}
                      />
                      <StageCard
                        data={stagesData[3]}
                      />
                      <StageCard
                        data={stagesData[4]}
                      />
                      <StageCard
                        data={stagesData[5]}
                      />
                    </Stages>
                  )
                }
              </div>
            </MainSection>
            <Sidebar>
              <div className={'sidebar--wrapper'}>
                <Avatar name={firstName} lastName={lastName} image={image} />
                {/*<ProgressBar title={'Twoja średnia'} type={'scale'} max={5} current={0} />*/}
                <ProgressBar title={'Twoje postępy'} type={'percent'} current={Math.round(dataUserMe.taskProgress)} />
                <div className={'sidebar--cards'}>
{/*                  <StageCard
                    className='special'
                    data={isTeacher ? lecturerData : stagesData[8]}
                  />*/}
                  <StageCard
                    data={{
                      ...stagesData[7],
                      disabled: !dataUserMe.currentTask,
                      link: dataUserMe.currentTask ? `/my-studies/tasks/${dataUserMe.currentTask}` : `/my-studies/tasks/`
                    }}
                  />
                </div>
              </div>
            </Sidebar>
          </Wrapper>
        </Container>
        <SliderWrapper>
          <Slider
            data={slide1Data}
            component={<Card />}
            breakpoints={{
              1400: {
                slidesPerView: 3
              },
              900: {
                slidesPerView: 2
              },
            }}
            isCenter={true}
            bgColor={'lightGray'}
          />
        </SliderWrapper>
      </>
    )
  }
}

const Wrapper = styled.div`
  max-width: 1625px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${props => props.theme.mobile}) {
    flex-direction: row;
    &::after {
    content: ' ';
    background-color: ${props => props.theme.colors.primaryLight};
    height: 150vh;
    width: 29%;
    position: absolute;
    right: 0;
    z-index: 0;
    top: 0;
  }
  }
`;

const Sidebar = styled.div`
  background-color: ${props => props.theme.colors.primaryLight};
  color: black;
  right: 0;
  display: block;
  height: 100%;
  top: 0;
  width: calc(100% + 24px);
  z-index: 2;
  position: relative;
  margin: 50px -12px -12px;
  @media screen and (min-width: ${props => props.theme.mobile}) {
    margin-top: -100px;
    max-width: 30%;
    width: 100%;
    &::after {
    content: ' ';
    position: absolute;
    background-color: ${props => props.theme.colors.primaryLight};
    width: 100%;
    height: 200%;
    top: -50%;
    left: 0;
    z-index: 1;
  }
    .sidebar--wrapper {
      max-width: 386px!important;
      margin: 0!important;
    }
    
  }
  .sidebar--wrapper {
    max-width: 500px;
    background-color: ${props => props.theme.colors.primaryLight};
    color: black;
    height: 100%;
    padding: 42px 23px;
    z-index: 2;
    position: relative;
    margin: 0 auto;
    .sidebar--cards {
      margin-top: 20px;
      > * {
        margin-bottom: 30px;
        @media screen and (max-width: ${props => props.theme.mobile}) {
          margin: 0 auto 30px;
        }
        }
    }
  }
`;

const Term = styled.dl`
    display: block;
    margin-bottom: 15px;
    dt {
      font-size: 20px;
      font-family: Lato, sans-serif;
      display: inline;
      font-weight: normal;
    }
  dd {
    display: inline;
    font-family: 'Merriweather', serif;
    font-size: 24px;
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
    margin-left: 10px;
  }
`;

const Stages = styled.div`
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    height: auto;
    flex-wrap: wrap;
    gap: 30px;
    @media screen and (max-width: ${props => props.theme.mobile}) {
      justify-content: center;
    }
    > div {
      flex: 0 0 33.333333%;
      @media screen and (max-width: 1570px) {
        flex: 0 0 50%;
    }
      @media screen and (max-width: ${props => props.theme.mobile}) {
        flex: 0 0 100%;
      }
    }
`;

const MainSection = styled.div`
    margin-top: 37px;
    max-width: 70%;
    margin-right: auto;
    @media screen and (max-width: ${props => props.theme.mobile}) {
      max-width: 100%; 
  }
  .container {
    max-width: 1090px;
    margin-left: auto;
    display: block;
  }
  ${Term}:nth-of-type(2) dd {
    margin-left: 15px;
  }
`;

const SliderWrapper = styled.div`
  @media screen and (max-width: ${props => props.theme.mobile}) {
    margin: -12px -12px -12px;
  }
`;

export default Studies